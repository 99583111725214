import React from "react"
import {Card, Button} from 'react-bootstrap'
import styled from '@emotion/styled'
import { Link } from "gatsby"
import { css } from "@emotion/react"


const PortfolioCardUl = styled.ul`
  list-style-type: none;
  margin-left: 2rem;
  padding-left: 0;
  li {
    position: relative;
    margin-bottom: 0.5rem;
  }
  li > span {
    position: relative;
    left: -0.5rem;
    top: 0.2rem;
}
`


export default function PortfolioCard(props) {
  
  return(
    <Card>
      <Card.Img variant="top" src={props.cardImage} />
      <Card.Body className="d-flex flex-column">
        {/* im Card.Body sind d-flex und felx-column erforerlich, damit die Buttons mit mt-auto
        immer ans Ende der Card gestestllt werden, auch wenn diese unerschiedlich groß sind */}
        <Card.Title>{props.title}</Card.Title>
            <div className="card-text mt-4">
              <PortfolioCardUl>
                {props.children}
              </PortfolioCardUl>
            </div>
            <div className="mt-auto">

              {/* das mt-auto stellt die Buttons ans Ende der Card
              Das funktioniert allerdings nur, wenn insgesamt kein DIV mehr um den Card Body ist
              Link macht automatisch eine text-decoration um den Text des Buttons (underline); für Normalzustand und hover muss man das (in Link) entfernen*/}
              <Link to={props.buttonDetailsLinkTo} css={css`text-decoration: none; &:hover {text-decoration: none;}`}><Button variant="secondary" block className="mb-2">Details</Button></Link>
              <Link to={props.buttonAppointmentLinkTo} css={css`text-decoration: none; &:hover {text-decoration: none;}`}><Button variant="primary" block>Termin anfragen</Button></Link>
            </div>
      </Card.Body>
    </Card>
  )
}