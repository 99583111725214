import React from "react"
import { Carousel } from 'react-bootstrap'
import styled from '@emotion/styled'
import ContentContainer from "../components/contentcontainer"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons'
import { breakpoints } from "../components/theme"

const TestimonialContainer = styled.div`


  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media (min-width: ${breakpoints.md}px) {
    padding: 2rem 4rem;
    height: 300px;
  }
  @media (max-width: ${breakpoints.md}px) {
    padding: 2rem;
    height: 320px;
  }
`

const Quote = styled.p`
  margin-top: 1.5rem;
  
  @media (min-width: ${breakpoints.md}px) {
    font-size: 1.6rem;
  }
  @media (max-width: ${breakpoints.md}px) {
    font-size: 1.2rem;
  }
`

const CarouselContainer = styled.div`
  // der flex Container von Bootstrap hat 15px padding-left und right
  // damit das Carousel über die gesamte Breite geht, müssen wir margings mit -15px machen
  /* margin-left: -15px;
  margin-right: -15px; */
  background-color: #f8f8f8;
`

export default function Testimonals() {
  return (
    <CarouselContainer>
      <ContentContainer>
        <Carousel>
          <Carousel.Item>
            <TestimonialContainer>
              <p><FontAwesomeIcon icon={faQuoteLeft} size="2x" /></p>
              <Quote>"Jovana hat ein tolles Auge für die richtigen Momente und immer gute Vorschläge. Alles in allem, lockere Atmosphäre und sehr schöne Fotos."</Quote>
              <p>- Daphne aus Köln</p>
            </TestimonialContainer>
          </Carousel.Item>

          <Carousel.Item>
            <TestimonialContainer>
              <p><FontAwesomeIcon icon={faQuoteLeft} size="2x" /></p>
              <Quote>"Wow - so verliebt wie wir in unseren kleinen Sohn sind, so verliebt sind wir in die Fotos, die Jovana von ihm und uns gemacht hat."</Quote>
              <p>- Nadine aus Köln</p>
            </TestimonialContainer>
          </Carousel.Item>

          <Carousel.Item>
            <TestimonialContainer>
              <p><FontAwesomeIcon icon={faQuoteLeft} size="2x" /></p>
              <Quote>"Es waren zwei tolle Tage, wir hatten sehr viel Spaß und haben uns mit Jovana mega wohl gefühlt. Sie hat unsere Wünsche super umgesetzt und hatte selbst einige tolle Anregungen."</Quote>
              <p>- Matthias aus Köln</p>
            </TestimonialContainer>
          </Carousel.Item>

          <Carousel.Item>
            <TestimonialContainer>
              <p><FontAwesomeIcon icon={faQuoteLeft} size="2x" /></p>
              <Quote>"Jovana ist super nett, hat sich viel Zeit genommen und ist toll mit unserer Tochter umgegangen, sodass es auch für die Kleine  sehr entspannt war."</Quote>
              <p>- Christina aus Köln</p>
            </TestimonialContainer>
          </Carousel.Item>
        </Carousel>
      </ContentContainer>
    </CarouselContainer>
  )
}
