import React from "react"
import { css } from "@emotion/react"
import styled from '@emotion/styled'
import Layout from "../components/layout"
import ImageGallery from "../components/imagegallery"
import Testimonals from "../components/testimonials"
import PortfolioCardSection from "../components/portfoliocardsection"
import CtaSection from "../components/ctasection"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/seo"



const LeadContainer = styled.div` 
    max-width: 500px;
    margin-right: auto;
    margin-left: auto;
`

const PageHeading = styled.h1`
  text-align: center;
@media (min-width: 768px) {
  //font-size: 42px;
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 1rem;
  line-height: 1.4;
}
@media (max-width: 768px) {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  line-height: 1.4;
}
`

const Introduction = styled.p`
  text-align: center;
  font-size: 20px;
  margin-bottom: 4rem;
`

export default function Home() {
  // mache eine Abfrage über gatsby-filesystem und gatsby-image mit graphQL
  // das Ergebnis ist ein Array von allen Images in dem Verzeichnis images/home
  // mit gatsby-images (zu erkennen an childrenImageSharp) werden diese auf dem Weg netterweise noch geresized
  const data = useStaticQuery(graphql`
    query {
      images: allFile(filter: {extension: {eq: "jpg"}, relativeDirectory: {eq: "images/home"}}, sort: {order: ASC, fields: name}) {
        edges {
          node {
            id
            childrenImageSharp {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)



  // über das Ergebnis der Graphql-Abfrage (gespeichert in data), wird geloopt
  // und für jedes Image aus der Abfrage der ImageGallery ein neues Child <Img> übergeben
  // Das [0] steht drinnen, da es im Array nur ein Element gibt

  //console.log(data.allFile.edges[0].node.childrenImageSharp[0].fluid.srcSet)
  //console.log(data.allFile.edges[0].node.childrenImageSharp[0].fluid.src)
  //console.log(data)
  return (
    <div>
      <Layout>
        <SEO
          title="Natürliche Babyfotografie in Köln"
          description="Fotografin für ihre natürlichen und liebevollen Babybauch- und Babyfotos in Köln und Umgebung. Reserviert euren Termin jetzt online. Viele Bilder, Preise und Infos."
        />
        <LeadContainer>
          <PageHeading>Lasst Erinngerungen lebendig werden</PageHeading>
          <Introduction>Hallo, ich bin Jovana - eure Fotografin für natürliche Baby- und Schwangerschaftsfotos aus Köln</Introduction>
        </LeadContainer>

        <div>
          <ImageGallery imageData={data.images}></ImageGallery>
        </div>

        <div css={css`margin-top: 4rem;`}>
          <CtaSection />
        </div>

        <div css={css`margin-top: 3rem;`}>
          <Testimonals />
        </div>

        <div id="portfolio" css={css`margin-top: 3rem; margin-bottom: 4rem;`}>
          <PortfolioCardSection />
        </div>


      </Layout>
    </div>
  )
}
