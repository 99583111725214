import React from "react"
import styled from '@emotion/styled'
import { css } from "@emotion/react"
import ContentContainer from "./contentcontainer"
import { colors, breakpoints } from "./theme"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { faCamera } from '@fortawesome/free-solid-svg-icons'
import { faCalendarCheck } from '@fortawesome/free-solid-svg-icons'
import { Col, Row } from "react-bootstrap"
import { Link } from "gatsby"

const CtaContainer = styled(ContentContainer)`
  @media (min-width: ${breakpoints.sm}px) {
    padding-right: 6rem;
    padding-left: 6rem;
  }
`

const ImageContainerStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 2rem;
`

const RowCheckDate = () => {

  return (
    <Row>
      <Col md="4" sm="12" className="order-md-12" css={ImageContainerStyle}>
        <FontAwesomeIcon icon={faCamera} size="5x" color={colors.primary} />
      </Col>
      <Col md="8" sm="12" className="order-md-1 mb-3" >
        <p css={css`font-size: 20px;`}>Du hast schon einen Wunschtermin? Prüfe jetzt direkt und ganz unverbindlich, ob der Wunschtermin für dein Shooting noch frei ist!</p>
        <p><Link to="/contact/reservation">Termin unverbindlich prüfen <span css={css`padding-left: 0.5rem;`}><FontAwesomeIcon icon={faChevronRight} size="1x" /></span></Link></p>
      </Col>
    </Row>
  )
}

const RowCalcBestDate = () => {

  return (
    <Row css={css`margin-top: 1rem;`}>
      <Col md="4" sm="12" css={ImageContainerStyle}>
        <FontAwesomeIcon icon={faCalendarCheck} size="5x" color={colors.primary} />
      </Col>
      <Col md="8" sm="12">
        <p css={css`font-size: 20px;`}>Du weißt noch nicht, welches der beste Shooting-Termin für dich ist? Berechne hier kostenlos und unverbindlich den besten Shooting-Termin für dich.</p>
        <p><Link to="/contact/calculatedate">Besten Termin berechnen <span css={css`padding-left: 0.5rem;`}><FontAwesomeIcon icon={faChevronRight} size="1x" /></span></Link></p>
      </Col>
    </Row>
  )
}

export default function CtaSection() {


  return (
    <CtaContainer>
      <RowCheckDate />
      <RowCalcBestDate />
    </CtaContainer>
  )
}
