import React from "react"

import ImgBelly from "../images/portfolio/belly.jpg"
import ImgNewborn from "../images/portfolio/newborn.jpg"
import ImgBaby from "../images/portfolio/baby.jpg"
import PortfolioCard from "./portfoliocard"
import ContentContainer from "./contentcontainer"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { Row, Col } from 'react-bootstrap'

const PortfolioCardListItem = props => (
  <li><span><FontAwesomeIcon icon={faCheck} listItem /></span>{props.children}</li>
)

export default function PortfolioCardRow(props) {

  return (
    <ContentContainer>
      <Row>

        <Col xs={12} sm={4} className="mb-4 d-flex">
          <PortfolioCard
            title="Babybauch Foto-Shooting"
            cardImage={ImgBelly}
            buttonDetailsLinkTo="/portfolio/belly/"
            buttonAppointmentLinkTo="/contact/reservation"
          >
            <PortfolioCardListItem>Individuelle Vorab-Beratung</PortfolioCardListItem>
            <PortfolioCardListItem>Alle Fotos als JPEG</PortfolioCardListItem>
            <PortfolioCardListItem>Liebevolle Bildbearbeitung</PortfolioCardListItem>
            <PortfolioCardListItem>Dauer: 1-1,5 Stunden</PortfolioCardListItem>
            <PortfolioCardListItem>Ort: zuhause oder an einem Ort eurer Wahl</PortfolioCardListItem>
            <PortfolioCardListItem>Preis: 299€</PortfolioCardListItem>
          </PortfolioCard>
        </Col>

        <Col xs={12} sm={4} className="mb-4 d-flex">
          <PortfolioCard
            title="Newborn Foto-Shooting"
            cardImage={ImgNewborn}
            buttonDetailsLinkTo="/portfolio/newborn/"
            buttonAppointmentLinkTo="/contact/reservation"
          >
            <PortfolioCardListItem>Individuelle Vorab-Beratung</PortfolioCardListItem>
            <PortfolioCardListItem>Alle Fotos als JPEG</PortfolioCardListItem>
            <PortfolioCardListItem>Liebevolle Bildbearbeitung</PortfolioCardListItem>
            <PortfolioCardListItem>Dauer: 1,5-2,5 Stunden</PortfolioCardListItem>
            <PortfolioCardListItem>Ort: zuhause oder an einem Ort eurer Wahl</PortfolioCardListItem>
            <PortfolioCardListItem>Preis: 399€</PortfolioCardListItem>
          </PortfolioCard>
        </Col>

        <Col xs={12} sm={4} className="mb-4 d-flex">
          <PortfolioCard
            title="Baby Foto-Shooting"
            cardImage={ImgBaby}
            buttonDetailsLinkTo="/portfolio/baby/"
            buttonAppointmentLinkTo="/contact/reservation"
          >
            <PortfolioCardListItem>Individuelle Vorab-Beratung</PortfolioCardListItem>
            <PortfolioCardListItem>Alle Fotos als JPEG</PortfolioCardListItem>
            <PortfolioCardListItem>Liebevolle Bildbearbeitung</PortfolioCardListItem>
            <PortfolioCardListItem>Dauer: 0,5-1 Stunde</PortfolioCardListItem>
            <PortfolioCardListItem>Ort: zuhause oder an einem Ort eurer Wahl</PortfolioCardListItem>
            <PortfolioCardListItem>Preis: 249€</PortfolioCardListItem>
          </PortfolioCard>
        </Col>

      </Row>
    </ContentContainer>
  )
}